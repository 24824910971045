import { motion } from 'framer-motion'
import { Col } from 'react-bootstrap'

import logo from "./../oxygen_logo_white.svg";

import video from './../OXYGEN_WEBSITE_ANIMATION_002.mp4'

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2, duration: 0.75 }
  },
  exit: {
    y: "-100vw",
    transition: { ease: 'easeInOut' }
  }
}

function Screenings({ timeLeft }) {
  return (
    <motion.div className="App-body screenings"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <video playsInline autoPlay muted loop poster="polina.jpg" id="bgvid">
        <source src={video} type="video/mp4"/>
      </video>
      <div className="container-fluid">
        <div className="row justify-content-left vertical-center">
          <Col xs={8} md={3} className="text-start">
            <div className="vstack gap-3 screenings-text">
            <div className="row justify-content-left">
              <div className="col-6 text-start">
                <p className="text-uppercase">
                  <div className="time-left">{timeLeft}</div>
                  DAYS TO GO UNTIL OUR FIRST SCREENING
                </p>
            </div>
          </div>
          <div className="screenings-copy ms-auto">
            <h1 className="text-uppercase">SCREENINGS</h1>
            <p>
              The first Oxygen screening will take place on April 22nd at the
              Curzon Hoxton, and will be acompelling immersive event.
            </p>
            <p>
              Tickets are limited, so if you’d like to be involved please get in
              touch using the details in the contact page.
            </p>
          </div>
          <small>Image courtesy of Sony Pictures Classic</small>
            </div>

          </Col>
        </div>
        <img src={logo} className="App-logo" alt="Oxygen film festival logo" />
      </div>
      </motion.div>
  );
}

export default Screenings;

import { motion } from 'framer-motion'
import { Col } from 'react-bootstrap'
import logo from './../oxygen_logo_black.svg'
import background from './../oxygen_bg002.jpg'

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2, duration: 0.75 }
  },
  exit: {
    y: "-100vw",
    transition: { ease: 'easeInOut' }
  }
}

function About() {
  return (
    <motion.div className="App-body about"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="container-fluid" style={{
        backgroundImage: "url(" + background + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className="row justify-content-center vertical-center">
          <Col xs={10} md={6}>
            <img src={logo} className="App-logo" alt="Oxygen film festival logo" />
            <h1 className="text-uppercase">SEE. FEEL. ACT.</h1>
            <div className="two-columns text-start">
              <p>
                Film is a unique art form that has the power to move, inspire,
                and motivate.
              </p>
              <p>
                It crosses all barriers of age, race, gender and religion,
                providing a platform for entertainment, cultural exchange and
                powerful messages of change.
              </p>
              <p>
                Oxygen will harness this powerful medium to drive change, bring
                people together and inspire immediate action on the UN SDGs.
              </p>
              <p>
                The Oxygen Film Festival provides a platform for stunning
                stories of sustainability and positivity.
              </p>
              <p>
                In keeping with our aim of positive motivation, every Oxygen
                event will not only entertain but motivate people to act.
              </p>
              <p>
                Through screenings, immersive entertainment and special events,
                Oxygen breathes life into a tired world.
              </p>
            </div>
          </Col>
        </div>
      </div>
    </motion.div>
  );
}

export default About;

import './App.css'
import { useEffect, useState } from "react"
import {
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom"
import { AnimatePresence } from 'framer-motion'
import 'bootstrap/dist/css/bootstrap.min.css'
import Nav from 'react-bootstrap/Nav'
import Home from './components/Home'
import About from './components/About'
import Screenings from './components/Screenings'
import Contact from './components/Contact'

function App() {
  const location = useLocation()
  const calculateTimeLeft = () => {
    const difference = +new Date('2022-4-23') - +new Date()
    let timeLeft = ''

    if (difference > 0) {
      timeLeft = Math.floor(difference / (1000 * 60 * 60 * 24))
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
  const [page, setPage ] = useState('home')

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 60000)
  })

  useEffect(() => {
    const path = location.pathname.substring(1)
    path.length < 1 ? setPage('home') : setPage(path)
  }, [location])

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return
    }
    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    )
  })

  return (
    <>
      <div className={`App ${page}`}>
        <header className="fixed-top">
          <Nav className="justify-content-center text-uppercase" defaultActiveKey="/home" as="ul">
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/about">About</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/screenings">Screenings</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            </Nav.Item>
          </Nav>
        </header>
        <AnimatePresence>
          <Routes location={location} key={location.key}>
            <Route path="/" element={<Home timeLeft={timeLeft}/>}/>
            <Route path="/about" element={<About />}/>
            <Route path="/screenings" element={<Screenings timeLeft={timeLeft}/>}/>
            <Route path="/contact" element={<Contact />}/>
          </Routes>
        </AnimatePresence>
      </div>
    </>
  )
}

export default App

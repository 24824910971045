
import { motion } from 'framer-motion'
import { Col } from 'react-bootstrap'
import logo from './../oxygen_logo_white.svg'
import video from './../OXYGEN_HOMEPAGE_ANIMATION_001.mp4'

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2, duration: 0.75 }
  },
  exit: {
    y: "-100vw",
    transition: { ease: 'easeInOut' }
  }
}

function Home({ timeLeft }) {

  return (
    <motion.div className="App-body home"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <video playsInline autoPlay muted loop poster="polina.jpg" id="bgvid">
        <source src={video} type="video/mp4"/>
      </video>
      <div className="row justify-content-center vertical-center">
        <Col xs={8} md={3} className="text-start">
          <img src={logo} className="App-logo" alt="Oxygen film festival logo" />
          <p className="text-center">The Oxygen Film Festival transforms the vision and emotion of film into action.</p>
          <div className="container">
            <div className="d-flex flex-row align-items-center days-to-go">
              <div>
                <div className="time-left text-end">{timeLeft}</div>
              </div>
              <div>
                <p className="text-uppercase">
                  DAYS TO GO UNTIL OUR FIRST SCREENING
                </p>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </motion.div>
  )
}

export default Home

import { motion } from 'framer-motion'
import { Col } from 'react-bootstrap'
import logo from './../oxygen_logo_black.svg'
import background from './../oxygen_bg001.jpg'

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2, duration: 0.75 }
  },
  exit: {
    y: "-100vw",
    transition: { ease: 'easeInOut' }
  }
}

function Contact() {

  return (
    <motion.div className="App-body contact"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="container-fluid" style={{
        backgroundImage: "url(" + background + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
      <div className="row justify-content-start vertical-center">
        <Col xs={8} md={3} className="text-start">
          <div className="contact-text">
            <h1 className="text-uppercase">CONTACT</h1>
            <p>for all media and partnership enquiries please get in touch with...</p>
            <p className="text-uppercase"><b>IMOGEN COOPER</b><br/>
            ----<br/>
            <a href="mailto:imogen@unfoldstories.co.uk">imogen@unfoldstories.co.uk</a><br/>
            ----<br/>
            +44 (0) 7896 789 674</p>
          </div>
          <img src={logo} className="App-logo" alt="Oxygen film festival logo" />
          </Col>
        </div>

        </div>
    </motion.div>
  );
}

export default Contact
